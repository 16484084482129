<template>
    <div class="flex">
        <div class="flex-grow flex w-full space-x-4 py-4 hover:text-black">
        <!-- <div class="flex-grow flex w-full space-x-4 items-center bg-beige border border-beige rounded-lg p-4"> -->
            <div class="flex-grow">
                {{userPassed.given_name || userPassed.given_name ? userPassed.given_name + ' ' + userPassed.family_name : userPassed.email}}
            </div>
            <div class="flex-none">
                <SvgIcon 
                    v-if="userPassed.clicks"
                    icon="star" 
                    class="w-4 h-4 text-green"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userPassed: {
            type: Object,
            required: true,
        }
    },
    
    computed:{
        departments(){
            return this.$store.getters.getDepartments;
        },
    },
}
</script>

<style lang="scss">
</style>